const firebaseConfig = {
  apiKey: "AIzaSyC40_PgKO0HaAf-YktPnSsetdHp6hULeGM",
  authDomain: "test-sfs-899c3.firebaseapp.com",
  projectId: "test-sfs-899c3",
  storageBucket: "test-sfs-899c3.firebasestorage.app",
  messagingSenderId: "1059316413075",
  appId: "1:1059316413075:web:c9a6404a5370e4ecc71366",
  measurementId: "G-5XP733W20R"
};

// Initialize Firebase
export default firebaseConfig;
