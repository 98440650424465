import Types from "./Types";

const INIT_STATE = {
  id: null,
  name: null,
  description: null,
  image: null,
  is_active: null,
  created_at: null,
  updated_at: null,
};

export default function blogReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.LIST_BLOG_SUCCESS: {
      // console.log("payloaddddd", payload.data?.health_blogs

      console.log("stateeee", state.blog);
      return {
        ...state,
        blog: {
          data: {
            health_blogs: [
              ...(state.blog?.data?.health_blogs || []),
              ...(payload?.data?.health_blogs || []),
            ],
            
          },
          pagination: payload?.pagination,
        },
      };
    }
    case Types.LIST_BLOG_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_BLOG_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_BLOG_SUCCESS: {
      return {
        ...state,
        singleBlog: payload,
      };
    }
    default: {
      return state;
    }
  }
}
