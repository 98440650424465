const contactusType = {
  ADD_ORDER_SUCCESS: "ADD_ORDER_SUCCESS",
  ADD_ORDER_LOADING: "ADD_ORDER_LOADING",
  ADD_APPLICANT_TO_ORDER_SUCCESS: "ADD_APPLICANT_TO_ORDER_SUCCESS",
  ADD_APPLICANT_TO_ORDER_LOADING: "ADD_APPLICANT_TO_ORDER_LOADING",
  REMOVE_APPLICANT_FROM_ORDER_SUCCESS: "REMOVE_APPLICANT_FROM_ORDER_SUCCESS",
  REMOVE_APPLICANT_FROM_ORDER_LOADING: "REMOVE_APPLICANT_FROM_ORDER_LOADING",
  UPDATE_APPLICANT_IN_ORDER_SUCCESS: "UPDATE_APPLICANT_IN_ORDER_SUCCESS",
  UPDATE_APPLICANT_IN_ORDER_LOADING: "UPDATE_APPLICANT_IN_ORDER_LOADING",
  GET_ORDER_SUCCESS: "GET_ORDER_SUCCES",
  GET_ORDER_LOADING: "GET_ORDER_LOADING",
  CHECKOUT_SUCCESS: "CHECKOUT_SUCCESS",
  CHECKOUT_LOADING: "CHECKOUT_LOADING",
  HISTORICAL_ORDERS_LOADING: "HISTORICAL_ORDERS_LOADING",
  HISTORICAL_ORDERS_SUCCESS: "HISTORICAL_ORDERS_SUCCESS",
  GET_CURRENT_ORDERS_SUCCESS: "GET_CURRENT_ORDERS_SUCCESS",
  GET_CURRENT_ORDERS_LOADIONG: "GET_CURRENT_ORDERS_LOADING",
};
export default contactusType;
