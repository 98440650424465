import { listBlogLoading, listBlog,
  getBlogLoading,
  getBlogSuccess
 } from "./Actions";

import { BaseURL } from "../../utils/constant";
import axios from "axios";
import handleErrors from "../../utils/HandelError";
import { toast } from "react-toastify";

export const listBlogsRequest = (page=1,limit=10) => async (dispatch) => {
  dispatch(listBlogLoading({ type: "blog", isLoading: true }));
  try {
    const { data } = await axios.get(`${BaseURL}/healths?page=${page}&per_page=${limit}`);
    dispatch(listBlog(data));
  } catch (error) {
    handleErrors(error);
  } finally {
      dispatch(listBlogLoading({ type: "blog", isLoading: false }));
  }
};

export const getBlogRequest = (id) => async (dispatch) => {
  dispatch(getBlogLoading({ type: "blog", isLoading: true }));
  try {
    const { data } = await axios.get(`${BaseURL}/healths/${id}`);
    dispatch(getBlogSuccess(data));
  } catch (error) {
    handleErrors(error);
  } finally {
    dispatch(getBlogLoading({ type: "blog", isLoading: false }));
  }
};