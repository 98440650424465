const contactusType = {
  LIST_APPLICANTS_SUCCESS: "LIST_APPLICANTS_SUCCESS",
  LIST_APPLICANTS_LOADING: "LIST_APPLICANTS_LOADING",
  LIST_APPLICANTS_FAIL: "LIST_APPLICANTS_FAIL",
  LIST_SCHOOL_NAMES_SUCCESS: "LIST_SCHOOL_NAMES_SUCCESS",
  LIST_SCHOOL_NAMES_LOADING: "LIST_SCHOOL_NAMES_LOADING",
  LIST_EDUCATIONAL_LEVELS_SUCCESS: "LIST_EDUCATIONAL_LEVELS_SUCCESS",
  LIST_EDUCATIONAL_LEVELS_LOADING: "LIST_EDUCATIONAL_LEVELS_LOADING",
  LIST_HEALTH_ISSUES_SUCCESS: " LIST_HEALTH_ISSUES_SUCCESS",
  LIST_HEALTH_ISSUES_LOADING: "  LIST_HEALTH_ISSUES_LOADING",
  DELETE_APPLICANT_SUCCESS: "DELETE_APPLICANT_SUCCESS",
  DELETE_APPLICANT_LOADING: "DELETE_APPLICANT_LOADING",
  DELETE_APPLICANT_FAIL: "DELETE_APPLICANT_FAIL",
  ADD_APPLICANT_SUCCESS: "ADD_APPLICANT_SUCCESS",
  ADD_APPLICANT_LOADING: "ADD_APPLICANT_LOADING",
  ADD_APPLICANT_FAIL: "ADD_APPLICANT_FAIL",
  EDIT_APPLICANT_SUCCESS: "EDIT_APPLICANT_SUCCESS",
  EDIT_APPLICAT_LOADING: "EDIT_APPLICANT_LOADING",
  GET_APPLICANT_SUCCESS: "GET_APPLICANT_SUCCESS",
  GET_APPLICANT_LOADING: "GET_APPLICANT_LOADING",
};
export default contactusType;
