// import AboutUsPage from "./Components/Pages/AboutUsPage/AboutUs.page";
// import AccountPage from "./Components/Pages/AccountPage/Account.page";
// import AddApplicantsPage from "./Components/Pages/AddApplicantsPage/AddApplicants.page";
// import AddApplicantsTemplate from "./Components/Templates/AddApplicantsTemplate/AddApplicants.template";
// import ApplicantForm from "./Components/Organisms/Applicants/ApplicantForm/ApplicantForm";
// import ApplicantsPage from "./Components/Pages/ApplicantsPage/Applicants.page";
// import CartAddApplicantPage from "./Components/Pages/CartAddApplicantPage/CartAddApplicant.page";
// import CartPage from "./Components/Pages/CartPage/Cart.page";
// import CheckoutPage from "./Components/Pages/CheckoutPage/Checkout.page";
// import ChooseApplicantsPage from "./Components/Pages/ChooseApplicantsPage/ChooseApplicants.page";
// import ContactUsPage from "./Components/Pages/ContactUsPage/ContactUs.page";
// import CurrentOrdersPage from "./Components/Pages/CurrentOrdersPage/CurrentOrders.page";
// import EditAccountPage from "./Components/Pages/EditAccountPage/EditAccount.page";
// import EditApplicantPage from "./Components/Pages/EditApplicantPage/EditApplicant.page";
// import HistoricalOrdersPage from "./Components/Pages/HistoricalOrdersPage/HistoricalOrders.page";
// import HomePage from "./Components/Pages/HomePage/Home.page";
// import Layout from "./Components/Organisms/Layout/Layout";
// import NewPasswordPage from "./Components/Pages/NewPasswordPage/NewPassword.page";
// import NotificationPage from "./Components/Pages/NotificationPage/Notification.page";
// import ProfilePage from "./Components/Pages/ProfilePage/Profile.page";
// import RateOrderPage from "./Components/Pages/RateOrderPage/RateOrder.page";
// import SigninPage from "./Components/Pages/SigninPage/Signin.page";
// import SignupPage from "./Components/Pages/SignUpPage/Signup.page";
// import SubscriptionConfirmPage from "./Components/Pages/SubscriptionConfirmPage/SubscriptionConfirm.page";
// import VerificationPage from "./Components/Pages/Verificationpage/Verification.page";
// import ForgotPasswordPage from "./Components/Pages/ForgotPasswordPage/ForgotPassword.page";

// import { Navigate } from "react-router";
// export const routes = [
//   // { name: "Sign up", path: "/signup", exact: true, element: <SignupPage /> },
//   // { name: "Sign in", path: "/signin", exact: true, element: <SigninPage /> },
//   // {
//   //   name: "Verification",
//   //   path: "/verify",
//   //   exact: true,
//   //   element: <VerificationPage />,
//   // },
//   // {
//   //   name: "NewPassword",
//   //   path: "/resetpassword",
//   //   exact: true,
//   //   element: <NewPasswordPage />,
//   // },
//   // {
//   //   name: "Verification",
//   //   path: "/verify",
//   //   exact: true,
//   //   element: <VerificationPage />,
//   // },
//   // {
//   //   name: "sendMessage",
//   //   path: "/sendMessage",
//   //   exact: true,
//   //   element: <SendMessagePage />,
//   // },
//   {
//     name: "Home",
//     path: "/",
//     exact: true,
//     element: <HomePage />,
//   },
//   {
//     name: "About",
//     path: "/about",
//     exact: "true",
//     element: <AboutUsPage />,
//   },
//   {
//     name: "Conatct us",
//     path: "/contact",
//     exact: "true",
//     element: <ContactUsPage />,
//   },
//   {
//     name: "Notification",
//     path: "/notification",
//     exact: "true",
//     element: <NotificationPage />,
//   },
//   {
//     name: "Checkout",
//     path: "/checkout",
//     exact: "true",
//     element: <CheckoutPage />,
//   },
//   {
//     name: "Profile",
//     path: "/profile",
//     exact: true,
//     element: <ProfilePage />,
//     children: [
//       {
//         name: "My Account",
//         path: "/profile/my-account",
//         children: [
//           {
//             name: "Add Applicants",
//             path: "/profile/my-account/applicants/add-applicants",
//             element: <AddApplicantsPage />,
//           },
//           {
//             name: "Add Applicants",
//             path: "/profile/my-account/applicants/edit-applicants/:id",
//             element: <EditApplicantPage />,
//           },
//           {
//             name: "Applicants",
//             path: "/profile/my-account/applicants",
//             element: <ApplicantsPage />,
//           },
//           {
//             name: "Account",
//             path: "/profile/my-account/account-setting",
//             element: <AccountPage />,
//           },
//           {
//             name: "Edit Account",
//             path: "/profile/my-account/account-setting/edit",
//             element: <EditAccountPage />,
//           },
//           {
//             name: "Payment",
//             path: "/profile/my-account/payment",
//             element: <h1>Payment</h1>,
//           },
//         ],
//       },
//       {
//         name: "My Orders",
//         path: "/profile/my-orders",
//         children: [
//           {
//             name: "Current Orders",
//             path: "/profile/my-orders/current-orders",
//             element: <CurrentOrdersPage />,
//           },
//           {
//             name: "History",
//             path: "/profile/my-orders/history",
//             element: <HistoricalOrdersPage />,
//           },
//         ],
//       },
//     ],
//   },
//   // {
//   //   name: "Page not found",
//   //   path: "*",
//   //   element: <h1>You are already logged in, please sign out first</h1>,
//   // },
//   {
//     name: "Cart",
//     path: "/cart",
//     exact: true,
//     element: <CartPage />,
//     children: [
//       {
//         name: "Add Applicant Cart",
//         path: "/cart/choose-applicants",
//         element: <ChooseApplicantsPage />,
//       },
//       {
//         name: "Add Applicant Cart",
//         path: "/cart/add-applicant",
//         element: <CartAddApplicantPage />,
//       },
//     ],
//   },
//   {
//     name: "Subscription confirmed",
//     path: "/sub-successful",
//     element: <SubscriptionConfirmPage />,
//   },
//   {
//     name: "Rate order",
//     path: "/add-review/:id",
//     element: <RateOrderPage />,
//   },
// ];

// export const authenticationRoutes = [
//   {
//     name: "Home",
//     path: "/",
//     exact: true,
//     element: (
//       <Layout>
//         <HomePage />
//       </Layout>
//     ),
//   },
//   {
//     name: "About",
//     path: "/about",
//     exact: "true",
//     element: (
//       <Layout>
//         <AboutUsPage />
//       </Layout>
//     ),
//   },
//   {
//     name: "Conatct us",
//     path: "/contact",
//     exact: "true",

//     element: (
//       <Layout>
//         <ContactUsPage />
//       </Layout>
//     ),
//   },
//   { name: "Sign up", path: "/signup", exact: true, element: <SignupPage /> },
//   { name: "Sign in", path: "/signin", exact: true, element: <SigninPage /> },
//   {
//     name: "Forget password",
//     path: "/forgetPassword",
//     exact: true,
//     element: <ForgotPasswordPage />,
//   },
//   {
//     name: "NewPassword",
//     path: "/resetpassword",
//     exact: true,
//     element: <NewPasswordPage />,
//   },
//   { name: "Page not found", path: "*", element: <Navigate to="/signin" /> },
// ];
// export const verifyRoutes = [
//   {
//     name: "Verification",
//     path: "/verify",
//     exact: true,
//     element: <VerificationPage />,
//   },
// ];

import React, { lazy, Suspense } from "react";
import { Navigate } from "react-router";
import SinglePost from "./Components/Molecules/SinglePost/SinglePost.jsx";

const AboutUsPage = lazy(() =>
  import("./Components/Pages/AboutUsPage/AboutUs.page")
);
const Blog = lazy(() => import("./Components/Pages/BlogPage/Blog.page.jsx"));
const AccountPage = lazy(() =>
  import("./Components/Pages/AccountPage/Account.page")
);
const AddApplicantsPage = lazy(() =>
  import("./Components/Pages/AddApplicantsPage/AddApplicants.page")
);
const EditApplicantPage = lazy(() =>
  import("./Components/Pages/EditApplicantPage/EditApplicant.page")
);
const ApplicantsPage = lazy(() =>
  import("./Components/Pages/ApplicantsPage/Applicants.page")
);
const CartPage = lazy(() => import("./Components/Pages/CartPage/Cart.page"));
const CheckoutPage = lazy(() =>
  import("./Components/Pages/CheckoutPage/Checkout.page")
);
const ContactUsPage = lazy(() =>
  import("./Components/Pages/ContactUsPage/ContactUs.page")
);
const CurrentOrdersPage = lazy(() =>
  import("./Components/Pages/CurrentOrdersPage/CurrentOrders.page")
);
const EditAccountPage = lazy(() =>
  import("./Components/Pages/EditAccountPage/EditAccount.page")
);
const HistoricalOrdersPage = lazy(() =>
  import("./Components/Pages/HistoricalOrdersPage/HistoricalOrders.page")
);
const HomePage = lazy(() => import("./Components/Pages/HomePage/Home.page"));
const Layout = lazy(() => import("./Components/Organisms/Layout/Layout"));
const NewPasswordPage = lazy(() =>
  import("./Components/Pages/NewPasswordPage/NewPassword.page")
);
const NotificationPage = lazy(() =>
  import("./Components/Pages/NotificationPage/Notification.page")
);
const ProfilePage = lazy(() =>
  import("./Components/Pages/ProfilePage/Profile.page")
);
const RateOrderPage = lazy(() =>
  import("./Components/Pages/RateOrderPage/RateOrder.page")
);
const SigninPage = lazy(() =>
  import("./Components/Pages/SigninPage/Signin.page")
);
const SignupPage = lazy(() =>
  import("./Components/Pages/SignUpPage/Signup.page")
);
const SubscriptionConfirmPage = lazy(() =>
  import("./Components/Pages/SubscriptionConfirmPage/SubscriptionConfirm.page")
);
const VerificationPage = lazy(() =>
  import("./Components/Pages/Verificationpage/Verification.page")
);
const ForgotPasswordPage = lazy(() =>
  import("./Components/Pages/ForgotPasswordPage/ForgotPassword.page")
);
const ChooseApplicantsPage = lazy(() =>
  import("./Components/Pages/ChooseApplicantsPage/ChooseApplicants.page")
);
const CartAddApplicantPage = lazy(() =>
  import("./Components/Pages/CartAddApplicantPage/CartAddApplicant.page")
);

const Loader = <div>Loading...</div>;

export const routes = [
  {
    name: "Home",
    path: "/",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <HomePage />
      </Suspense>
    ),
  },
  {
    name: "About",
    path: "/about",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <AboutUsPage />
      </Suspense>
    ),
  },
  {
    name: "Contact us",
    path: "/contact",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <ContactUsPage />
      </Suspense>
    ),
  },
  {
    name: "Blog",
    path: "/blog",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Blog />
      </Suspense>
    ),
  },
  {
    name: "Blog post",
    path: "/blog/:id",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <SinglePost />
      </Suspense>
    ),
  },
  {
    name: "Notification",
    path: "/notification",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <NotificationPage />
      </Suspense>
    ),
  },
  {
    name: "Checkout",
    path: "/checkout",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <CheckoutPage />
      </Suspense>
    ),
  },
  {
    name: "Profile",
    path: "/profile",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <ProfilePage />
      </Suspense>
    ),
    children: [
      {
        name: "My Account",
        path: "/profile/my-account",
        children: [
          {
            name: "Add Applicants",
            path: "/profile/my-account/applicants/add-applicants",
            element: (
              <Suspense fallback={Loader}>
                <AddApplicantsPage />
              </Suspense>
            ),
          },
          {
            name: "Edit Applicants",
            path: "/profile/my-account/applicants/edit-applicants/:id",
            element: (
              <Suspense fallback={Loader}>
                <EditApplicantPage />
              </Suspense>
            ),
          },
          {
            name: "Applicants",
            path: "/profile/my-account/applicants",
            element: (
              <Suspense fallback={Loader}>
                <ApplicantsPage />
              </Suspense>
            ),
          },
          {
            name: "Account",
            path: "/profile/my-account/account-setting",
            element: (
              <Suspense fallback={Loader}>
                <AccountPage />
              </Suspense>
            ),
          },
          {
            name: "Edit Account",
            path: "/profile/my-account/account-setting/edit",
            element: (
              <Suspense fallback={Loader}>
                <EditAccountPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        name: "My Orders",
        path: "/profile/my-orders",
        children: [
          {
            name: "Current Orders",
            path: "/profile/my-orders/current-orders",
            element: (
              <Suspense fallback={Loader}>
                <CurrentOrdersPage />
              </Suspense>
            ),
          },
          {
            name: "History",
            path: "/profile/my-orders/history",
            element: (
              <Suspense fallback={Loader}>
                <HistoricalOrdersPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Cart",
    path: "/cart",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <CartPage />
      </Suspense>
    ),
    children: [
      {
        name: "Choose Applicants",
        path: "/cart/choose-applicants",
        element: (
          <Suspense fallback={Loader}>
            <ChooseApplicantsPage />
          </Suspense>
        ),
      },
      {
        name: "Add Applicant Cart",
        path: "/cart/add-applicant",
        element: (
          <Suspense fallback={Loader}>
            <CartAddApplicantPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    name: "Subscription confirmed",
    path: "/sub-successful",
    element: (
      <Suspense fallback={Loader}>
        <SubscriptionConfirmPage />
      </Suspense>
    ),
  },
  {
    name: "Rate order",
    path: "/add-review/:id",
    element: (
      <Suspense fallback={Loader}>
        <RateOrderPage />
      </Suspense>
    ),
  },
];

export const authenticationRoutes = [
  {
    name: "Home",
    path: "/",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <HomePage />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "Sign up",
    path: "/signup",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <SignupPage />
      </Suspense>
    ),
  },
  {
    name: "Sign in",
    path: "/signin",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <SigninPage />
      </Suspense>
    ),
  },
  {
    name: "About",
    path: "/about",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <AboutUsPage />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "Contact us",
    path: "/contact",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <ContactUsPage />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "Forget password",
    path: "/forgetPassword",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <ForgotPasswordPage />
      </Suspense>
    ),
  },
  {
    name: "Blog",
    path: "/blog",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <Blog />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "Blog post",
    path: "/blog/:id",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <SinglePost />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "NewPassword",
    path: "/resetpassword",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <NewPasswordPage />
      </Suspense>
    ),
  },
  {
    name: "Page not found",
    path: "*",
    element: <Navigate to="/signin" />,
  },
];

export const verifyRoutes = [
  {
    name: "Verification",
    path: "/verify",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <VerificationPage />
      </Suspense>
    ),
  },
];
