import React, { useEffect } from "react";
import ImagesSrc from "../../../utils/ImagesSrc";
import {
  getBlogRequest,
  listBlogsRequest,
} from "../../../Services/modules/blog";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SinglePost = ({ t }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blog?.singleBlog);
  const multiBlogs = useSelector((state) => state.blog?.blog?.data?.health_blogs);

  const lng = localStorage.getItem("language");

  useEffect(() => {
    dispatch(getBlogRequest(id));
    dispatch(listBlogsRequest(1,2));
  }, [id]);

  function truncateText(text, maxLength) {
    if (!text) return ""; // Handle empty or undefined text
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  }

  return (
    <main>
      <div className="lg:w-[84%] w-[90%] mx-auto pb-[80px] view-blog-banner">
        <div className="blog-single">
          <div className="blog-articles">
            <a
              href="#"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={ImagesSrc?.BlogArticles} alt="Articles" />
              <h2> {t("blogs.article")}</h2>
            </a>
          </div>
          <div className="blog-single-info">
            <div className="blog-single-banner">
              <img
                src={blogs?.image_url}
                alt="UX review presentations"
              />
            </div>
            <div className="blog-single-head">
              <h1>
                {" "}
                {lng === "ar"
                  ? blogs?.arabic_name || "Blog Description"
                  : blogs?.name || "Blog Description"}
              </h1>
              <div class="view-blog-date">
                <span className="view-blog-admin">{t("blogs.Created")} </span>{" "}
                <span class="view-blog-month">
                  {new Date(blogs?.created_at).toLocaleDateString() ||
                    "Unknown"}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="blog-single-detail">
          <div className="blog-single-left">
            <p>
              {" "}
              {lng === "ar"
                ? blogs?.arabic_description || "Blog Description"
                : blogs?.description || "Blog Description"}
            </p>
          </div>
          <div className="blog-single-right">
            {multiBlogs?.length > 0 ? (
              multiBlogs.map((blog) => (
                <div className="view-blog-info" key={blog.id}>
                  <div className='full-clickable'>
                  <Link to={`/blog/${blog?.id}`}>{t("blogs.readbtn")}</Link>
                     </div>
                  <img
                    src={blog?.image_url ||
                      "/default-image.jpg"
                    }
                    alt={blog?.name || "Blog Image"}
                  />
                  <div className="view-blog-product">
                    <h2>
                      {" "}
                      {lng === "ar"
                        ? truncateText(blog?.arabic_name || "Blog Title", 10)
                        : truncateText(blog?.name || "Blog Title", 45)}
                    </h2>
                    <p className="">
                      {lng === "ar"
                        ? truncateText(blog?.arabic_name || "Blog Title", 10)
                        : truncateText(blog?.name || "Blog Title", 45)}
                    </p>
                  </div>
                  <div className="view-blog-date">
                    <span className="view-blog-admin">
                    <Link to={`/blog/${blog?.id}`}>{t("blogs.readbtn")}</Link>

                      {/* {t("blogs.Created")}{" "} */}
                    </span>
                    <span className="view-blog-month">
                      {" "}
                      {new Date(blog?.created_at).toLocaleDateString() ||
                        "Unknown"}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <p> {t("blogs.NoData")}</p>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default withTranslation()(SinglePost);
