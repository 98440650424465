const authType = {
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_LOADING: "SIGN_IN_LOADING",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_LOADING: "SIGN_UP_LOADING",
  SIGN_UP_FAIL: "SIGN_UP_FAIL",
  VERIFY_SUCCESS: "VERIFY_SUCCESS",
  VERIFY_LOADING: "VERIFY_LOADING",
  PRE_REGISTER_SUCCESS: "PRE_REGISTER_SUCCESS",
  AUTH_LOADING: "AUTH_LOADING",
  FORGOT_PASSWORD_OTP_LOADING: "FORGOT_PASSWORD_OTP_LOADING",
  FORGOT_PASSWORD_OTP_SUCCESS: "FORGOT_PASSWORD_OTP_SUCCESS",
  FORGOT_PASSWORD_OTP_FAIL: "FORGOT_PASSWORD_OTP_FAIL",
  RESET_PASSWORD_VERIFY_SUCCESS: "RESET_PASSWORD_VERIFY_SUCCESS",
  RESET_PASSWORD_VERIFY_FAIL: "RESET_PASSWORD_VERIFY_SUCCESS",
  RESET_PASSWORD_VERIFY_LOADING: "RESET_PASSWORD_VERIFY_LOADING",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",
  CHANGE_PASSWORD_LOADING: "CHANGE_PASSWORD_LOADING",
  GET_USER_EXISTENCE_SUCCESS: "GET_USER_EXISTENCE_SUCCESS",
  GET_USER_EXISTENCE_LOADING: "GET_USER_EXISTENCE_LOADING",
  GET_USER_EXISTENCE_FAIL: "GET_USER_EXISTENCE_FAIL",
  SET_FIREBASE_TOKEN: "SET_FIREBASE_TOKEN",
};
export default authType;
